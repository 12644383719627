import React from "react";
import {Icon} from "@iconify/react";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {Container} from "semantic-ui-react";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

let DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
	iconAnchor:   [25, 41],
	popupAnchor: [-12, -38]
});

export const Map = (props) => {

	const position = [51.505, -0.09];

	return (

		<div id="mapbox" className="text-center">
			<div style={{paddingTop: "0px"}}>

				<MapContainer
					center={[55.67602967267581, 12.532272934913637]}
					zoom={16} dragging={false}
					doubleClickZoom={false}
					scrollWheelZoom={false}
					zoomControl={false}
					style={{
						height: "450px",
						width: "100%",
						//border: "1px solid black"
					}}
					attributionControl={false}
				>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>

					<Marker
						position={[55.67602967267581, 12.532272934913637]}
						icon={DefaultIcon}
						eventHandlers={{
							click: (e) => {
								console.log('marker clicked', e)
							},
						}}
					>
					</Marker>

				</MapContainer>

			</div>
		</div>

	);
};