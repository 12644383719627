import React from "react";
import {Icon} from "@iconify/react";
import {Image} from "semantic-ui-react";

export const Services = (props) => {
	return (
		<div id="services" className="text-center" style={{"background": "#214150"}}>
			<div className="container">
				<div className="section-title">
					<h2>Julefrokost</h2>
					<p>
						Julefrokost på Haven, med god mad, hygge og gang i dansegulvet
					</p>
				</div>
				<div className="row">
					{props.data
						? props.data.map((d, i) => (
							<div key={`${d.name}-${i}`} className="col-md-4">
								{" "}
								<Icon icon={d.icon} style={{ fontSize: '10em' }} />
								<div className="service-desc">
									<h3>{d.name}</h3>
									<p>{d.text}</p>
								</div>
							</div>
						))
						: "loading"}
				</div>

				<div className="row">
					<a href='/pdf/Haven_Julefrokost 2024_A4.pdf' target='_blank' rel='noopener noreferrer'>
						<Image wrapped={true}  centered={true} src="/pdf/Haven_Julefrokost 2024_230x320-1.png" size="large"/>
					</a>
				</div>

			</div>
		</div>
	);
};
