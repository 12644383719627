import React from "react";
import {Icon} from "@iconify/react";

export const Features = (props) => {
	return (
		<div id="features" className="text-center" style={{"paddingBottom": "75px"}}>
			<div className="container" style={{paddingTop: "100px"}}>
				<div className="col-md-10 col-md-offset-1 section-title" style={{"marginBottom": "0px"}}>
					<h2>Selskaber</h2>
					<p>
						På Haven har vi stor erfaring med at afholde selskaber. Alt fra firmafester, fødselsdage til bryllupsfester og konfirmationer. I det historiske lokale tæt på Frederiksberg Have danner vi gerne rammerne om jeres næste arrangement.
					</p>
				</div>

				<div className="row">
					{props.data
						? props.data.map((d, i) => (
							<div key={`${d.title}-${i}`} className="col-md-4" style={{"paddingTop": "40px"}}>
								{" "}
								{/*<i className={d.icon}></i>*/}
								<Icon icon={d.icon} style={{ fontSize: '10em' }} />
								<h3>{d.title}</h3>
								<p>{d.text}</p>
							</div>
						))
						: "Loading..."}
				</div>
			</div>
		</div>
	);
};
